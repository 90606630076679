import React from "react";
import styled from "@emotion/styled"

import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import { Template } from "@narative/gatsby-theme-novela/src/types";

const TheDiv = styled.div`
color: ${p => p.theme.colors.primary};
`

const MainPage: Template = ({ location, pageContext }) => {
  return (
    <Layout>
      <SEO pathname={location.pathname} />
        <Section><TheDiv style={{fontSize: '4em'}}>404</TheDiv></Section>
    </Layout>
  );
};

export default MainPage;


